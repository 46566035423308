* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
  --blue-1: hsl(231, 75%, 10%);
  --blue-2: hsl(231, 75%, 5%);
  --light-gold: hsl(51, 100%, 92%);

  padding: 0;
  margin: 0;
  background-color: var(--blue-1);
  color: var(--light-gold);
  font-family: 'Comfortaa', cursive;
  
}

a {
  display: block;
  text-decoration: none;
  color: inherit;
}


.fav {
  height: 100vh;
  background-color: var(--blue-1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.fav--container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid var(--light-gold);
  border-radius: 100%;
}

.favicon {
  font-size: 7rem;
  color: var(--light-gold);
  font-family: "Monoton", cursive;
  height: 9rem;
  width: 9rem;
}

/* Navbar ------------------------------------------------------------------- */

.nav {
  width: 100%;
  background-color: var(--blue-1);
  position: fixed;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 5px 5px 5px hsla(0, 0%, 0%, 25%) !important;
  z-index: 7;
}

.nav--logo--container {
  margin-left: 2rem;
}

.nav--logo {
  font-family: "Permanent Marker", cursive;
  font-family: 'Monoton', cursive;
  font-size: 2rem;
  border: 3px solid var(--light-gold);
  border-radius: 100%;
  width: 2.8rem;
  height: 2.8rem;
}

.nav--list {
  padding-right: 2rem;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
}


.nav--list a {
  padding: 1.2rem 0;
  border-bottom: 2px solid var(--blue-1);
  transition: 0.2s;
}

.nav--list a:hover {
  border-bottom: 2px solid var(--light-gold);
}

.contact--button {
  border: 2px solid var(--light-gold);
  padding: 0.5rem;
  border-radius: 0.3rem;
  transition: 0.3s;
}

.contact--button:hover {
  font-weight: bold;
  color: var(--blue-1);
  background-color: var(--light-gold);
  border-color: var(--blue-1);
  cursor: pointer;
}

.language {
  width: 5rem;
  height: 100%;
  padding: 0.6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: relative;
}

.flag--container {
  width: auto;
  height: 2.7rem;
  padding: 0.3rem;
}

.flag {
  z-index: 3;
  height: 100%;
  width: auto
}

.language--dropdown {
  
  list-style: none;
  width: 5rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  z-index: -1;
  background-color: hsl(231, 75%, 10%);
  padding: 1rem;
  border-radius: 0.3rem;
  box-shadow: 0px 5px 5px 5px hsla(0, 0%, 0%, 25%) !important;
  top: -20rem;
  transition: 0.5s;
}

.language:hover .language--dropdown{
  top: -1rem;
} 

.language--space {
  height: 5rem;
}

.language--option {
  border-radius: 0.5rem;
  height: 2.7rem;
  padding: 0.3rem;
}

.language--option:hover {
  
  box-shadow: inset 10rem 1rem var(--blue-1);
  box-shadow: 0px 0px 5px 2px var(--light-gold);
  cursor: pointer;
}

.menu--icon {
  display: none;
}


@media (max-width: 900px){

  .nav--list {
    gap: 1.5rem;
    padding-right: 1rem;
  }

  .nav--list a {
    font-size: 0.8rem;
  }

  .contact--button {
    font-size: 0.8rem;
  }

}

/* mobile navbar */
@media (max-width: 700px){


  .nav {
    border-bottom: 2px solid var(--light-gold);
    padding: 0.5rem;
    height: 12vh;
  }

  .nav--logo--container {
    margin-left: 0.5rem;
  }

  .nav--logo {
    height: 3rem;
    width: 3rem;
  }

  .nav--list {
    top: 12vh;
    height: 88vh;
    left: 0rem;
    position: absolute;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem 0rem;
    background-color: var(--blue-1);
    gap: 0.7rem;
    box-shadow: 0px 0px 5px 5px hsla(0, 0%, 0%, 25%) !important;
    transition: 0.5s;
  }

  .nav--list--hidden {
    transform: translateX(-100%);
  }
  
  
  .nav--list a {
    width: 100%;
    font-size: 1.3rem ;
    padding: 1rem;
  }

  .contact--button {
    font-size: 1.3rem;
    padding: 1rem;
  }

  .language {
    flex-direction: row;
    justify-content: center;
    width: 6rem;
    height: 5rem;
  }
  
  .flag--container {
    width: 5rem;
    height: 4rem;
  }
  
  
  .language--dropdown {
    display: none;
    width: auto;
    position: relative;
    justify-content: center;
    gap: 0.6rem;
    z-index: 0;
    top: 0px;
    flex-direction: row;
    padding: 0.3rem;
    background: none;
    box-shadow: 0px 0px 0px 0px hsla(0, 0%, 0%, 0%) !important;
    margin-left: 0.7rem;

  }

  .language--space {
    display: none;
  }

  .language:hover .language--dropdown{
    top: 0rem;
    display: flex;
  } 

  .language--option {
    width: 5rem;
    height: 4rem;
  }

  .language--option:hover {
    box-shadow: none !important;
  }

  .menu--icon {
    position: absolute;
    right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    border: 2px solid var(--light-gold);
    padding: 0.5rem;
  }

  .menu--bar {
    height: 3px;
    width: 100%;
    border-radius: 10%;
    background-color: var(--light-gold);
    transition: 0.3s;
  }

  .bar--1 {
    position: relative;
    bottom: 0.085rem;
    width: 141%;
    transform-origin: left;
    transform: rotate(45deg);
  }

  .bar--2 {
    background-color: hsla(0, 0%, 0%, 0%);
  }

  .bar--3 {
    position: relative;
    top: 0.085rem;
    width: 141%;
    transform-origin: left;
    transform: rotate(-45deg);
  }
  
}


/* Socials ------------------------------------------------------------------ */

.socials {
  position: fixed;
  bottom: 0;
  right: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 60vh;
  z-index: 4;
  gap: 0.7rem;

}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--light-gold);
  font-size: 2rem;
  border-radius: 100%;
  border: 2px solid hsla(0, 0%, 0%, 0%);
  transition: 0.3s;
  padding: 0.7rem;
}

.social a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social:hover {
  border-radius: 100%;
  border: 2px solid var(--light-gold);
  cursor: pointer;
}

.social--svg {
  width: 2rem;
  height: 2rem;
} 

.socials--trail {
  width: 1px;
  flex-grow: 1;
  background-color: var(--light-gold);
}

@media ( max-width: 950px ){

  .social--svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .socials {
    right: 2rem;
    height: 40vh;
    gap: 0.5rem;
  
  }

}

@media ( max-width: 650px ){


  .socials {
    right: 1rem;
    height: 40vh;
    gap: 0.5rem;
  
  }

}

/* contact form ------------------------------------------------------------- */

.contact--form--container {
  position: fixed;
  z-index: 10;
  height: 100vh;
  width: 100%;
  background-color: hsla(0, 0%, 0%, 40%);
  color: var(--light-gold);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.contact--form--container--hidden {
  transform: translateX(-100%);
}

.contact--form {
  position: relative;
  height: 80%;
  width: 70%;
  background-color: hsla(0, 0%, 0%, 80%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 0.5rem;
}

.contact--form--exit--btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 2.7rem;
  width: 2.7rem;
  color: var(--light-gold);
  font-size: 3rem;
  background-color: hsla(0, 0%, 0%, 0%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;
  cursor: pointer;
}

.contact--form--exit--btn::after {
  position: absolute;
  content: "\00d7";
  display: inline-block;
}

.contact--form--exit--btn:hover {
  transform: scale(0.8);
}

.contact--form--title {
  font-family: 'Maven Pro', sans-serif;
  font-size: 2.5rem;
}

.text--input {
  display: block;
  width: 70%;
  height: 2.2rem;
  border: none;
  border-radius: 0.3rem;
  padding: 0.5rem;
  font-size: 1.2rem;
  font-family: 'Comfortaa', cursive;
}

.text--input:focus {
  outline-width: 0;
  box-shadow: 0px 0px 5px 3px hsl(231, 75%, 70%);
}

.contact--form--message {
  display: block;
  width: 70%;
  height: 6rem;
  border-radius: 0.3rem;
  font-family: 'Comfortaa', cursive;
  font-size: 1.2rem;
  padding: 0.5rem;
}

.contact--form--message:focus {
  outline-width: 0;
  box-shadow: 0px 0px 5px 3px hsl(231, 75%, 70%);
}

.contact--form--send--btn {
  background-color: var(--blue-1);
  border-radius: 0.5rem;
  border: 2px solid var(--light-gold);
  color: var(--light-gold);
  font-size: 1.2rem;
  padding: 0.5rem 1.2rem;
  font-weight: bold;
  transition: background-color color 0.3s;
  cursor: pointer;
}

.contact--form--send--btn:hover {
  background-color: var(--light-gold);
  color: var(--blue-1);
}

@media(max-width: 750px){
  .contact--form {
    height: 90%;
    width: 100%;
  }
  
  .contact--form--title {
    font-family: 'Maven Pro', sans-serif;
    font-size: 1.8rem;
    margin-top: 2rem;
  }
  
  .text--input {
    font-size: 1rem;
  }
  
  .contact--form--message {
    font-size: 1rem;
  }
}

/* welcome section ---------------------------------------------------------- */

.welcome {
  margin-top: 0px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0rem 5rem;

}

.hi {
  font-size: 1rem;
}

.hi2 {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.name {
  font-size: 6rem;
  font-family: 'Permanent Marker', cursive;
  font-family: 'Monoton', cursive;
  font-weight: normal;
}

.job-title {
  font-size: 3rem;
  padding: 0rem 2rem;
}

/* media queries */

@media (max-width: 1050px){

  .welcome {
    gap: 2rem;
  }

  .name {
    font-size: 5rem;
    line-height: 1;
  }

  .hi {
    font-size: 0.9rem;
  }
  
  .hi2 {
    font-size: o.9rem;
  }

  .job-title {
    font-size: 2.5rem;
  }

}

@media (max-width: 450px ) {

  .welcome {
    padding: 0rem 3rem;
  }

  .name {
    font-size: 3.5rem;
  }

  .hi {
    font-size: 0.7rem;
  }
  
  .hi2 {
    font-size: 0.7rem;
  }

  .job-title {
    font-size: 1.6rem;
  }
}

@media(max-width: 350px){
  .welcome {
    padding: 0rem 2rem;
    margin-bottom: 3rem;
  }

  .name {
    font-size: 3rem;
  }

  .hi {
    font-size: 0.6rem;
  }
  
  .hi2 {
    font-size: 0.6rem;
  }

  .job-title {
    font-size: 1.4rem;
  }
}

/* about section ---------------------------------------------------------- */

.about {
  height: 100vh;
  text-align: left;
  color: var(--light-gold);
  background-color: var(--blue-1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 10rem;
  position: relative;

}

.about--content {
  display: flex;
  height: 60%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.about--background {
  color: hsla(0, 0%, 50%, 10%);
  position: absolute;
  z-index: 1;
  font-size: 10rem;
  font-weight: bolder;
  left: 0;
  transform: translateX(-15rem);
  opacity: 0;
  transition: 1s;
  transition-delay: 0.5s;
}

.about--background--visible{
  transform: translateX(0rem);
  opacity: 1;
}

.about--text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 50%;
  height: 100%;
  line-height: 1.6;
}

.about--title {
  font-family: 'Maven Pro', sans-serif;
  position: relative;
  z-index: 1;
  font-size: 3rem;
  padding: 1rem;
  margin: 0;
  opacity: 0;
  transform: translateY(3rem);
  transition: 1s;
  transition-delay: 0.5s;
}

.about--title--visible {
  opacity: 1;
  transform: translateY(0rem);
}

.about--description {
  color: hsl(0, 0%, 70%);
  position: relative;
  z-index: 1;
  font-size: 1.3rem;
  padding: 1rem;
  opacity: 0;
  transform: translateY(3rem);
  transition: 1s;
  transition-delay: 1s;
}

.about--description--visible {
  opacity: 1;
  transform: translateY(0rem);
}

.about--description p {
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}

.about--picture {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 18rem;
  box-shadow: 0px 0px 5px 5px hsla(0, 0%, 0%, 50%);
  margin-left: 1rem;
  z-index: 2;
  background-color: white;
  color: var(--blue-1);
  padding: 0.3rem;
  border-radius: 0.3rem;
  opacity: 0;
  transform: rotateY(90deg);
  transition: 1s;
  transition-delay: 1s;
}

.about--picture--visible {
  opacity: 1;
  transform: rotateY(0deg);
}

.my--pic {
  height: 90%;
  width: 100%;
  background-size: cover;
  background-position-x: 40%;
}

.pic--footer {
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Caveat', cursive;
  font-size: 1.3rem;
  text-align: center;
}

@media (max-width: 1250px){

  .about {
    padding: 0rem 8rem;
  }

  .about--title {
    font-size: 2.5rem;
    padding: 0.8rem;
  }
  
  .about--description {
    font-size: 1.1rem;
    padding: 0.8rem;
  }
  .about--background {
    font-size: 8rem;
  }
  
}

@media (max-width: 1050px){

  .about {
    padding: 0rem 6rem;
  }

  .about--title {
    font-size: 2.3rem;
    padding: 0.7rem;
  }
  
  .about--description {
    font-size: 1rem;
    padding: 0.7rem;
  }
  .about--background {
    font-size: 7rem;
  }
  
}

@media (max-width: 950px){

  .about {
    height: auto;
    padding: 5rem 5rem;
  }

  .about--content {
    flex-direction: column;
    height: 100%;
  }

  .about--text {
    width: 100%;
    padding: 0;
  }

  .about--title {
    font-size: 2.3rem;
    padding: 0.7rem;
  }
  
  .about--description {
    font-size: 1rem;
    padding: 0.7rem;
  }

  .about--background {
    font-size: 7rem;
    bottom: 30rem;
  }

  .about--picture {
    height: 28rem;
    margin-left: 0;
    margin-top: 3rem;
  }
}

@media (max-width: 900px) {
  .about--background {
    top: 0rem;
  }
}

@media (max-width: 600px) {

  .about {
    padding: 5rem 4rem;
  }

  .about--background {
    font-size: 5rem;
  }

  .about--picture {
    height: 28rem;
  }
}

@media (max-width: 450px) {

  .about {
    padding: 5rem 2rem;
  }

  .about--title {
    font-size: 2rem;
  }

  .about--description {
    font-size: 0.9rem;
  }

  .about--background {
    top: 20%;
    font-size: 4rem;
  }

  .about--picture {
    height: 23rem;
    width: 15rem;
  }
}


/* tools ------------------------------------------------------------*/

.tools {
  background-color: var(--blue-1);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  padding: 3rem;
  height: 110vh;
}

.tools--title {
  position: relative;
  font-family: 'Maven Pro', sans-serif;
  font-size: 3rem;
  color: var(--light-gold);
  text-align: center;
  opacity: 0;
  transform: translateY(3rem);
  transition: 1s;
  transition-delay: 0.5s;
}

.tools--title--visible{
  opacity: 1;
  transform: translateY(0rem);
}

.tools--text {
  position: relative;
  color:hsl(0, 0%, 70%);
  font-size: 1.3rem;
  line-height: 1.6;
  max-width: 60rem;
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem;
  opacity: 0;
  transform: translateY(3rem);
  transition: 1s;
  transition-delay: 1s;
}

.tools--text--visible{
  opacity: 1;
  transform: translateY(0rem);
}

.hex--container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  transform: translateX(-20%);
  opacity: 0;
  transition: 1.5s;
  transition-delay: 1.5s;
}

.hex--container--visible {
  transform: translateX(0%);
  opacity: 1;
}

.hex--row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hex--row--1 {
  transform: translateY(0.7rem) translateX(-2.2rem);
}

.hex--row--2 {
  transform: translateY(-0.8rem) translateX(2.3rem);
}


.hex--subrow {
  display: flex;
}

.hex {
  
	position: relative;
  width: 8rem;
	height: 9rem;
	background-color: none;
	margin: 0 0.4rem;
	transition: 0.7s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
}

.hex:hover {
  transform: rotate3d(0, 1, 0, 180deg);
}

/* clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%); */

.hex--front {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--blue-2);
  position: absolute;
  transform: translateZ(1px);
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
}

.hex--back {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--blue-2);
  height: 100%;
  width: 100%;
  font-size: 1.3rem;
  font-weight: bold;
  background-color: var(--light-gold);
  transform: rotateY(180deg) translateZ(1px);
  position: absolute;
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
}


.svg{
  width: 4.5rem;
  height: 4.5rem;
}

#html--svg {
  width: 4.2rem;
  height: 4.2rem;
}

#css--svg {
  width: 4.2rem;
  height: 4.2rem;
}

#js--svg {
  width: 3.5rem;
  height: 3.5rem;
}

#git--svg {
  width: 5.2rem;
  height: 5.2rem;
}

#flask--svg {
  width: 5rem;
  height: 5rem;
}

#vscode--svg {
  width: 4.2rem;
  height: 4.2rem;
}

.codepen--background {
  z-index: -1;
  position: absolute;
  border-radius: 100%;
  background-color: white;
  height: 3rem;
  width: 3rem;
}

@media (max-width: 1250px){

  .tools--title {
    font-size: 2.5rem;
  }
  
  .tools--text {
    font-size: 1.1rem;
    max-width: 50rem;
  }

  .hex {
    width: 7.5rem;
    height: 8.3rem;
  }

  .hex--row--1 {
    transform: translateY(0.7rem) translateX(-2.1rem);
  }
  
  .hex--row--2 {
    transform: translateY(-0.8rem) translateX(2rem);
  }
  
}

@media (max-width: 1050px){

  .tools--title {
    font-size: 2.3rem;
  }
  
  .tools--text {
    font-size: 1rem;
    max-width: 40rem;
  }

  .hex {
    width: 7rem;
    height: 7.8rem;
  }

  .hex--row--1 {
    transform: translateY(0.7rem) translateX(-2rem);
  }
  
  .hex--row--2 {
    transform: translateY(-0.8rem) translateX(1.9rem);
  }

  .hex--back {
    font-size: 1.2rem;
  }
  
}

@media (max-width: 950px){
  .tools {
    height: auto;
    padding: 7rem;
  }

  .tools--title {
    margin-bottom: 1rem;
  }
  
  .tools--text {
    max-width: 35rem;
  }

  .hex--row {
    flex-direction: column;
  }

  .sub--1 {
    transform: translateY(1.5rem) translateX(3.8rem);
  }

  .sub--4 {
    transform: translateY(-1.5rem) translateX(-3.8rem);
  }
  
}

@media (max-width: 600px){

  .tools {
    padding: 1.8rem;
    padding-top: 7rem;
  }

  .hex {
    width: 5.6rem;
    height: 6.25rem;
  }

  .hex--back {
    font-size: 1rem;
  }

  .hex--row--1 {
    transform: translateY(0.5rem) translateX(-1.7rem);
  }
  
  .hex--row--2 {
    transform: translateY(-0.6rem) translateX(1.6rem);
  }

  .sub--1 {
    transform: translateY(1rem) translateX(3.2rem);
  }

  .sub--4 {
    transform: translateY(-1rem) translateX(-3.2rem);
  }

  .svg{
    width: 3rem;
    height: 3rem;
  }

  #html--svg {
    width: 3rem;
    height: 3rem;
  }
  
  #css--svg {
    width: 3rem;
    height: 3rem;
  }
  
  #js--svg {
    width: 3rem;
    height: 3rem;
  }
  
  #git--svg {
    width: 4rem;
    height: 4rem;
  }
  
  #flask--svg {
    width: 3rem;
    height: 3rem;
  }
  
  #vscode--svg {
    width: 3rem;
    height: 3rem;
  }
}

@media (max-width: 450px){
  .tools {
    padding: 1rem;
    padding-top: 7rem;
  }

  .tools--title {
    font-size: 2rem;
    margin-bottom: 0.7rem;
  }
  
  .tools--text {
    font-size: 0.9rem;
    max-width: 20rem;
  }

  .hex {
    width: 4.75rem;
    height: 5.3rem;
  }

  .hex--back {
    font-size: 0.85rem;
  }

  .hex--row--1 {
    transform: translateY(0.4rem) translateX(-1.4rem);
  }
  
  .hex--row--2 {
    transform: translateY(-0.4rem) translateX(1.3rem);
  }

  .sub--1 {
    transform: translateY(0.7rem) translateX(2.8rem);
  }

  .sub--4 {
    transform: translateY(-0.7rem) translateX(-2.8rem);
  }

  #js--svg {
    width: 2.5rem;
    height: 2.5rem;
  }

}

@media (max-width: 350px){

  .hex--container {
    gap: 0.3rem;
  }

  .hex--row {
    flex-direction: row;
  }

  .hex--row--1 {
    transform: translateY(0rem) translateX(0rem);
  }
  
  .hex--row--2 {
    transform: translateY(0rem) translateX(0rem);
  }

  .hex--subrow {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 0.3rem;
  }

  .sub--1 {
    transform: translateY(0rem) translateX(0rem);
  }

  .sub--4 {
    transform: translateY(0rem) translateX(0rem);
  }

  .hex {
    width: 5.6rem;
    height: 6.25rem;
  }
}


/* projects --------------------------------------------------------- */

.projects {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: var(--blue-1);
  text-align: center;
}

.projects--header {
  font-family: 'Maven Pro', sans-serif;
  margin-top: 5rem;
  font-size: 3rem;
  margin-bottom: 5rem;
  opacity: 0;
  transform: translateY(3rem);
  transition: 1s;
  transition-delay: 0.5s;
}

.projects--header--visible {
  opacity: 1;
  transform: translateY(0rem);
}

.projects--grid {
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;

}

.project {
  color: white;
  height: 30rem;
  width: 1fr;
  background-color: hsl(0, 0%, 10%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 0.3rem;
  opacity: 0;
  transform: rotateY(90deg);
  transition: 1s;
  transition-delay: 0.5s;
}

.project--visible{
  opacity: 1;
  transform: rotateY(0deg);
}

.project--pic {
  position: absolute;
  width: 75%;
  height: 75%;
  top: 1rem;
  left: 1rem;
  background-size: cover;
  border-radius: 0.3rem;
}

.project--info {
  position: absolute;
  width: 55%;
  height: 55%;
  bottom: 1rem;
  right: 1rem;
  background-color: hsla(231, 75%, 5%, 92%);
  border-radius: 0.5rem;
  color: white;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
  line-height: 1.6;

}

.project--info p {
  font-size: 0.85rem;
  color: hsl(0, 0%, 60%);
}

#project--div--game {
  background-color: hsl(350, 47%, 42%);
}

#project--div--bookme {
  background-color: hsl(54, 88%, 67%);
}

#project--div--finance {
  background-color: hsl(191, 47%, 51%);
}

#project--div--portfolio {
  background-color: hsl(27, 100%, 71%);
}

#project--div--piano {
  background-color: hsl(105, 33%, 67%);
}

#project--div--decimale {
  background-color: hsl(225, 64%, 33%);
}

.project--github {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  bottom: 1rem;
  left: 1rem;
  height: 3.7rem;
  width: 3.7rem;
  border: 2px solid black;
  border-radius: 1rem;
  box-shadow: 0px 0px 3px 3px hsla(0, 0%, 50%, 50%);
  transition: 0.3s;
}


.project--github:hover {
  border-color: var(--light-gold);
  background-color: hsla(0, 0%, 0%, 25%);
}

.arrowSVGContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  bottom: 1rem;
  left: 1rem;
  height: 3.7rem;
  width: 3.7rem;
  border: 2px solid black;
  border-radius: 1rem;
  box-shadow: 0px 0px 3px 3px hsla(0, 0%, 50%, 50%);
  transition: 0.3s;
}

.arrowSVGContainer:hover {
  border-color: var(--light-gold);
  background-color: hsla(0, 0%, 0%, 25%);
}

.arrowSVG {
  fill: white;
  transform: rotateZ(60deg);
}

@media (max-width: 1250px){
  .project--header {
    font-size: 2.5rem;
  }
}


@media (max-width: 1200px){

  .projects--grid {
    grid-template-columns: 1fr;
  }

  .project {
    height: 80vh;
  }

  .project--info {
    padding: 1rem 4rem;
  }

  .project--info p {
    font-size: 1rem;
  }
}

@media (max-width: 1050px){
  .project--header {
    font-size: 2.3rem;
  }
}

@media (max-width: 950px){

  .project--info {
    padding: 1rem 3rem;
  }

  .project--info p {
    font-size: 0.9rem;
  }
}

@media (max-width: 600px ){
  
  .project {
    height: 30rem;
  }

  .project--pic {
    position: absolute;
  }

  .project--info {
    position: absolute;
    width: 75%;
    height: 55%;

  }
}

@media (max-width: 450px ){
  
  .project {
    padding: 0.5rem;
    height: 100vh;
    flex-direction: column;
    gap: 0.5rem;
  }

  .projects--header {
    font-size: 2rem;
  }

  .project--pic {
    position: static;
    width: 100%;
    height: 40%;
  }

  .project--info {
    position: static;
    width: 100%;
    height: 40%;
  }

  .project--github {
    position: static;
  }

  .arrowSVGContainer {
    position: static;
  }
}

@media(max-width: 350px){

  .project--pic {
    position: static;
    width: 100%;
    height: 35%;
  }

  .project--info {
    position: static;
    width: 100%;
    height: 45%;
  }

  .project--info h3 {
    font-size: 1.1rem;
  }

  .project--info p {
    font-size: 0.75rem;
  }
}

/* footer -------------------------------------------------------------------*/

.footer {
  background-color: var(--blue-1);
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
  padding-right: 2rem;
  padding-left: 2rem;
}


.footer > * {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.footer--links {
  display: flex;
  justify-content: center;
  gap: 3rem;
}

@media (max-width: 600px){
  footer > * {
    font-size: 0.85rem;
  }
}

@media (max-width: 450px){
  footer > * {
    font-size: 0.75rem;
  }
}


/* scrollbar --------------------------------------------------------------- */

::-webkit-scrollbar {
  width: 0.8rem;
}


/* Track */
::-webkit-scrollbar-track {
  background-color: var(--blue-2);
  border-radius: 100vh;
  margin: 1rem 0rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--blue-1);
  border-radius: 100vh;
  border: 1px solid var(--light-gold);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--light-gold);
  border: 1px solid var(--blue-2);
}


@supports (scrollbar-color: black white){
  * {
    scrollbar-color: hsl(51, 100%, 90%) hsl(231, 75%, 5%);
    scrollbar-arrow-color: hsl(51, 100%, 90%);
    scrollbar-width: auto;
  }
}
